import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import Footer from '@/components/Footer.vue';

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = '/';
axios.defaults.headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

Vue.config.productionTip = false;
Vue.prototype.$request = axios;

Vue.component('footer-block', Footer);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
