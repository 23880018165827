<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
require('@/assets/scss/core.scss');

export default {
};
</script>
