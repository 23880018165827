<template>
  <div class="footer">
    <p class="footer__txt">© {{ year }} Letique </p>
    <div class="footer-links">
      <a href="/offer.pdf" target="_blank" class="footer__txt footer-link">Публичная оферта</a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return (new Date()).getFullYear();
    },
  },
};
</script>
